export default function AsideMenu() {
    return (
      <>
      <div class="position-fixed rounded-circle z-index-2">
        <button type="button" class="btn share-button bg-gradient-success btn-lg rounded-circle text-white"
            data-container="body" data-bs-toggle="popover" data-color="success" data-bs-placement="top" data-bs-content='<ul class="list-group" aria-labelledby="dropdownMenuButton">
        <li class="list-group-item border-0 p-0">
          <a class="dropdown-item border-radius-md text-md" target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=elvisben.me.ke&quote=You%20need%20to%20checkout%20this%20site!%20">
            <i class="fa-brands fa-facebook"></i> Share on Facebook
          </a>
        </li>
        <li class="list-group-item border-0 p-0">
          <a class="dropdown-item border-radius-md text-md" target="_blank" href="https://twitter.com/intent/tweet?text=You%20need%20to%20checkout%20this%20site!%20&url=elvisben.me.ke">
            <i class="fa-brands fa-twitter"></i> Share on Twitter
          </a>
        </li>
        <li class="list-group-item border-0 p-0">
          <a class="dropdown-item border-radius-md text-md" target="_blank" href="https://wa.me/?text=You%20need%20to%20checkout%20this%20site!elvisben.me.ke">
            <i class="fa-brands fa-whatsapp"></i> Share on WhatsApp
          </a>
        </li>
      </ul>'>
            <i class="material-icons opacity-10">share</i>
        </button>
    </div>

    <aside class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-7 fixed-start ms-2 bg-gradient-dark h-auto" id="sidenav-main">
      <div class="collapse navbar-collapse w-auto" id="sidenav-collapse-main">
        <ul class="navbar-nav">
          <li class="nav-item mt-2 mb-2">
            <a class="nav-link text-white" href="./#">
              <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                <i class="material-icons opacity-10">home</i>
              </div>
              <span class="nav-link-text ms-1">Home</span>
            </a>
          </li>
          <li class="nav-item mt-2 mb-2">
            <a class="nav-link text-white " href="./find-players.html">
              <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                <i class="material-icons opacity-10">person_search</i>
              </div>
              <span class="nav-link-text ms-1">Find Players</span>
            </a>
          </li>
          <li class="nav-item mt-2 mb-2">
            <a class="nav-link text-white " href="./wagers.html">
              <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                <i class="material-icons opacity-10">sports_kabaddi</i>
              </div>
              <span class="nav-link-text ms-1">Wagers</span>
            </a>
          </li>
          <li class="nav-item mt-2 mb-2">
            <a class="nav-link text-white " href="./#" onclick="showAlert()">
              <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                <i class="material-icons opacity-10">leaderboard</i>
              </div>
              <span class="nav-link-text ms-1">Tournaments</span>
            </a>
          </li>
          <li class="nav-item mt-2 mb-2">
            <a class="nav-link text-white " href="./#" onclick="showAlert()">
              <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                <i class="material-icons opacity-10">groups</i>
              </div>
              <span class="nav-link-text ms-1">Clans</span>
            </a>
          </li>
        </ul>
      </div>
    </aside>
    </>
  )
}