import ReadCookie from './ReadCookie';
import { createContext, useContext, useEffect, useState } from 'react';

const AuthContext = createContext();

export function useAuth(){
  return useContext(AuthContext);
} 

export function AuthProvider (props) {
  const [authUser, setAuthUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(()=>{
    async function fetchUser() {
      const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
      const token = ReadCookie('smaak_token');
      if(!isLoggedIn && token && !authUser){
        try {
          const request = await fetch(`${apiBaseURL}/user`, {
            credentials: 'include',
            headers: {
              Authorization: "Bearer " + token
            }
          });
    
          const response = await request.json();
          
          setAuthUser(response);
          setIsLoggedIn(true);
        } catch (error) {
          setIsLoggedIn(false);
          setAuthUser(null);
        }
      }else{
        setIsLoggedIn(false);
        setAuthUser(null);
      }
    }
    fetchUser();
  }, [])

  const authenticationState = [
    authUser,
    setAuthUser,
    isLoggedIn,
    setIsLoggedIn
  ]

  return (
    <AuthContext.Provider value ={ authenticationState }>
      { props.children }
    </AuthContext.Provider>
  )
}
