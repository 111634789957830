import { Route, Routes, useLocation } from 'react-router-dom';

// styling
import './assets/css/material-dashboard.css?v=3.0.0';
import './App.css';

// components
import Account from './pages/account/Account';
import Verification from './pages/verification/Verification';
import Profile from './pages/profile/Profile';
import { useEffect, useState } from 'react';
import { Alert } from './components/Alerts';
import { AuthProvider } from './hooks/AuthContext';

function App() {
  const location = useLocation();
  const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  const [ alerts, setAlerts ] = useState([]);

  useEffect(() => {
    setTimeout(()=>{
      if(alerts.length > 0){
        alerts.pop();
        setAlerts(alerts);
      }
    },4000)
  }, [alerts]);


  return (
    <div className='container-fluid vh-100 vw-100 position-relative px-0 font-family-dmsans bg-light overflow-y-scroll overflow-x-hidden'>
        <Alert alerts = {alerts} />
        <AuthProvider>
        <Routes key={location.pathname} location={location}>
          <Route 
            path='/account' 
            element={
              <Account 
                apiBaseURL = {apiBaseURL} 
                setAlerts = {setAlerts} 
                globalAlerts = {alerts}/>
              }>
          </Route>
          <Route 
            path='/verification/:verificationtoken' 
            element={
              <Verification 
                apiBaseURL = {apiBaseURL} 
                setAlerts = {setAlerts} 
                globalAlerts = {alerts}/>
              }>
          </Route>
          <Route path='/profile' element={
            <Profile 
              apiBaseURL = {apiBaseURL} 
              setAlerts = {setAlerts} 
              globalAlerts = {alerts}
            />}></Route>
        </Routes>
        </AuthProvider>
    </div>
  );
}

export default App;
