export default function MobileNavigation() {
    return (
      <div class="mobile-navigation my-3 bg-gradient-dark w-100">
      <div class="my-auto">
        <a href="./" class="text-white d-flex flex-column text-center">
          <i class="material-icons opacity-10">home</i>
          <span class="text-xs">Home</span>
        </a>
      </div>
  
      <div class="my-auto ">
        <a href="./find-players.html" class="text-white d-flex flex-column text-center">
          <i class="material-icons opacity-10">person_search</i>
          <span class="text-xs">Players</span>
        </a>
      </div>
  
      <div class="my-auto">
        <a href="./wagers.html" class="text-white d-flex flex-column text-center">
          <i class="material-icons opacity-10">sports_kabaddi</i>
          <span class="text-xs">Wagers</span>
        </a>
      </div>
  
      <div class="my-auto">
        <a href="./#" class="text-white d-flex flex-column text-center" onclick="showAlert()">
          <i class="material-icons opacity-10">leaderboard</i>
          <span class="text-xs">Tournaments</span>
        </a>
      </div>
  
      <div class="my-auto">
        <a href="./#" class="text-white d-flex flex-column text-center" onclick="showAlert()">
          <i class="material-icons opacity-10">groups</i>
          <span class="text-xs">Clans</span>
        </a>
      </div>
    </div>
  )
}