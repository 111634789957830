export function Alert({alerts}){
    return (
        <div className='position-fixed top-0 end-1 w-auto'>
            {
                alerts.map(alert => {
                  if(alert.type === 'error'){
                    return (
                        <div class="alert alert-danger alert-dismissible fade show" role="alert">
                            <strong>Error!</strong> { alert.message }
                            <button type="button" class="btn-close p-0 m-0 d-flex justify-content-center align-items-center text-5xl h-100" data-bs-dismiss="alert" aria-label="Close">&times;</button>
                        </div>
                    )
                  } else if(alert.type === 'success') {
                    return (
                        <div class="alert alert-success alert-dismissible fade show" role="alert">
                            <strong>Success!</strong> { alert.message }
                            <button type="button" class="btn-close p-0 m-0 d-flex justify-content-center align-items-center text-5xl h-100" data-bs-dismiss="alert" aria-label="Close">&times;</button>
                        </div>
                    )
                  } else if(alert.type === 'info'){
                    return (
                      <div class="alert alert-info alert-dismissible fade show" role="alert">
                          { alert.message }
                          <button type="button" class="btn-close p-0 m-0 d-flex justify-content-center align-items-center text-5xl h-100" data-bs-dismiss="alert" aria-label="Close">&times;</button>
                      </div>
                    )
                  } else {
                    return ''
                  }
                })
            }
        </div>
    )
}