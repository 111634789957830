import MainNavigation from '../../components/MainNavigation';
import MobileNavigation from '../../components/MobileNavigation';
import AsideMenu from '../../components/AsideMenu';
import './Profile.css';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReadCookie from '../../hooks/ReadCookie';
import { useAuth } from '../../hooks/AuthContext';

function Profile({ apiBaseURL, setAlerts, globalAlerts }) {
  const navigate = useNavigate();

  const [
    authUser,
    setAuthUser,
    isLoggedIn,
    setIsLoggedIn
] = useAuth();

  const [ socialPlatforms, setSocialPlatforms ] = useState([]);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/account');
    }
  });

  useEffect(()=>{
    loadSocialPlatforms()
  }, [])

  const loadSocialPlatforms = async() => {
    try {
      const token = ReadCookie('smaak_token');
      const request = await fetch(`${apiBaseURL}/social-media-platforms`,{
        credentials:'include',
        headers: {
          Authorization: "Bearer " + token
        }
      });
  
      const response = await request.json();

      setSocialPlatforms(response)
    } catch (error) {
      globalAlerts.push({
        type: 'error',
        message: "Failed to load social platforms"
    })
      setAlerts(globalAlerts);
    }
    
  }

  const toggleMainText = (event) => {
    const radioBtn = event.currentTarget;
    const mainText = document.querySelectorAll('.main-text');
    mainText.forEach((main)=>{
      main.classList.add('invisible');
    })
    radioBtn.parentElement.parentElement.parentElement.querySelector('span').classList.toggle('invisible')
}
  return (
    <>
      <MainNavigation />
      <MobileNavigation />
      <AsideMenu />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg p-3 mt-8">
        <div className="row">
          <div className="col-12">
            <div className="row p-0 g-0 justify-content-between col-sm-4 mx-auto align-items-center align-content-center">

              <div className="col-2 cursor-pointer text-dark-custom text-center" data-bs-toggle="modal" data-bs-target="#friendsModal">
                <p className="text-2xl m-0 font-weight-bolder">{ authUser ? authUser.sum_user_friends : 0 }</p>
                <p className="m-0">Friends</p>
              </div>

              <div className="modal fade" id="friendsModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-fullscreen" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLongTitle">Friends</h5>
                      <button type="button" className="btn btn-danger close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">

                      <div className="d-flex flex-column justify-content-between p-1 col-sm-6 mx-auto">
                        <div className="height-100 align-items-center col-12 d-flex justify-content-between border-bottom">
                          <div className="col-2 col-sm-1 h-100 d-flex justify-content-center align-items-center">
                            <div className="mx-auto rounded-50 rounded-circle overflow-hidden h-50">
                              <img className="img-fluid w-100 h-100" src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/window-desk.jpg" alt="" />
                            </div>
                          </div>
                          <div className="col-7 col-sm-8 text-2xl text-dark-custom ps-2">
                            Username
                          </div>
                          <div className="col">
                            <button className="btn btn-danger">REMOVE</button>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-column justify-content-between p-1 col-sm-6 mx-auto">
                        <div className="height-100 align-items-center col-12 d-flex justify-content-between border-bottom">
                          <div className="col-2 col-sm-1 h-100 d-flex justify-content-center align-items-center">
                            <div className="mx-auto rounded-50 rounded-circle overflow-hidden h-50">
                              <img className="img-fluid w-100 h-100" src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/window-desk.jpg" alt="" />
                            </div>
                          </div>
                          <div className="col-7 col-sm-8 text-2xl text-dark-custom ps-2">
                            Username
                          </div>
                          <div className="col">
                            <button className="btn btn-danger">REMOVE</button>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-column justify-content-between p-1 col-sm-6 mx-auto">
                        <div className="height-100 align-items-center col-12 d-flex justify-content-between border-bottom">
                          <div className="col-2 col-sm-1 h-100 d-flex justify-content-center align-items-center">
                            <div className="mx-auto rounded-50 rounded-circle overflow-hidden h-50">
                              <img className="img-fluid w-100 h-100" src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/window-desk.jpg" alt="" />
                            </div>
                          </div>
                          <div className="col-7 col-sm-8 text-2xl text-dark-custom ps-2">
                            Username
                          </div>
                          <div className="col">
                            <button className="btn btn-danger">REMOVE</button>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-column justify-content-between p-1 col-sm-6 mx-auto">
                        <div className="height-100 align-items-center col-12 d-flex justify-content-between border-bottom">
                          <div className="col-2 col-sm-1 h-100 d-flex justify-content-center align-items-center">
                            <div className="mx-auto rounded-50 rounded-circle overflow-hidden h-50">
                              <img className="img-fluid w-100 h-100" src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/window-desk.jpg" alt="" />
                            </div>
                          </div>
                          <div className="col-7 col-sm-8 text-2xl text-dark-custom ps-2">
                            Username
                          </div>
                          <div className="col">
                            <button className="btn btn-danger">REMOVE</button>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-column justify-content-between p-1 col-sm-6 mx-auto">
                        <div className="height-100 align-items-center col-12 d-flex justify-content-between border-bottom">
                          <div className="col-2 col-sm-1 h-100 d-flex justify-content-center align-items-center">
                            <div className="mx-auto rounded-50 rounded-circle overflow-hidden h-50">
                              <img className="img-fluid w-100 h-100" src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/window-desk.jpg" alt="" />
                            </div>
                          </div>
                          <div className="col-7 col-sm-8 text-2xl text-dark-custom ps-2">
                            Username
                          </div>
                          <div className="col">
                            <button className="btn btn-danger">REMOVE</button>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex flex-column justify-content-between p-1 col-sm-6 mx-auto">
                        <div className="height-100 align-items-center col-12 d-flex justify-content-between border-bottom">
                          <div className="col-2 col-sm-1 h-100 d-flex justify-content-center align-items-center">
                            <div className="mx-auto rounded-50 rounded-circle overflow-hidden h-50">
                              <img className="img-fluid w-100 h-100" src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/window-desk.jpg" alt="" />
                            </div>
                          </div>
                          <div className="col-7 col-sm-8 text-2xl text-dark-custom ps-2">
                            Username
                          </div>
                          <div className="col">
                            <button className="btn btn-danger">REMOVE</button>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="mx-auto rounded-50 rounded-circle overflow-hidden width-6-rem height-6-rem">
                  <img className="img-fluid w-100 h-100 object-fit-cover" src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/window-desk.jpg" alt="" />
                </div>
                <p className="text-center text-dark-custom">{  authUser ? authUser.username : "johndoe" }</p>
              </div>
              <div className="col-2 text-dark-custom text-center">
                <p className="text-2xl m-0 font-weight-bolder">{  authUser ? authUser.sum_user_affiliate_code_shares : 0 }</p>
                <p className="m-0">Shares</p>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="col-sm-5">
              <div className="d-flex flex-column">
                <h3 className="text-dark-custom text-underline border-bottom border-4 border-dark-custom">
                  Social Media
                </h3>
                <ul className="list-group text-primary">

                  {
                    socialPlatforms.length > 0 ? 
                    socialPlatforms.map(platform => (
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        <span>
                          <i className={ platform.font_awesome_icon } style={{
                            'color': platform.font_awesome_brand_color
                          }}></i>
                          <span className="ms-1 text-dark-custom">{platform.human_readable_string} -
                            <button className="btn text-dark-custom py-0 m-0 px-1"><i className="fa-solid fa-wrench"></i></button>
                            <span className="font-weight-bold">XXXX</span></span>
                        </span>

                        <div>
                          <span className="text-success main-text invisible">Main</span>
                          <span className="badge badge-primary badge-pill">
                            <span className="form-check form-switch ps-0 mx-1 my-0">
                              <input className="form-check-input mt-1 ms-auto" type="radio" name="main" onChange={ (event) => toggleMainText(event) } />
                            </span>
                          </span>
                        </div>

                      </li>
                      ))
                    :
                    "No social platforms"
                  }
                </ul>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="w-100 text-center text-dark-custom text-2xl">
              Joined groups
            </div>
            <hr />
            <h4 className="text-dark-custom">
              Gamename find players
            </h4>
            <div className="my-4">
              <div className="d-flex justify-content-between mb-1">
                <div className="border bg-gradient-dark text-white">
                  00 new messages
                </div>
              </div>
              <div className="row border border-2 rounded-custom border-dark">
                <div className="col-sm-6">
                  <div className="row border-bottom border-dark py-2">
                    <div className="col-4 text-uppercase text-secondary text-lg text-dark font-weight-bold opacity-7">
                      Username
                    </div>
                    <div className="col-4 text-uppercase text-secondary text-lg text-dark font-weight-bold opacity-7">
                      Game
                    </div>
                    <div className="col-4 text-uppercase text-secondary text-lg text-dark font-weight-bold opacity-7">
                      Location
                    </div>
                  </div>
                  <div className="row border-dark py-2">
                    <div className="col-4 text-uppercase text-secondary text-xxs font-weight-light opacity-7 d-flex align-items-center">
                      <i className="material-icons opacity-10 text-success pe-2 magnify-md">account_circle</i>
                      <p className="text-dark text-xss my-auto text-truncate font-weight-bold">JohnMichael</p>
                    </div>
                    <div className="col-4 text-uppercase text-secondary text-xxs font-weight-light opacity-7 d-flex align-items-center">
                      <p className="text-dark text-xss my-auto">ABCDEF</p>
                    </div>
                    <div className="col-4 text-uppercase text-secondary text-xxs font-weight-light opacity-7 d-flex align-items-center">
                      <p className="text-dark text-xss my-auto">2/4</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="row border-bottom border-dark border-dark py-2">
                    <div className="col-3 text-uppercase text-secondary text-lg text-dark font-weight-bold opacity-7">
                      Age
                    </div>
                    <div className="col-3 text-uppercase text-secondary text-lg text-dark font-weight-bold opacity-7">
                      Device
                    </div>
                    <div className="col-3 text-uppercase text-secondary text-lg text-dark font-weight-bold opacity-7">
                      Slot
                    </div>
                    <div className="col-3 text-uppercase text-secondary text-lg text-dark font-weight-bold opacity-7">

                    </div>
                  </div>
                  <div className="row border-dark py-2">
                    <div className="col text-uppercase text-secondary text-xxs font-weight-light opacity-7 d-flex align-items-center">
                      <p className="text-dark text-xss my-auto">00</p>
                    </div>
                    <div className="col text-uppercase text-secondary text-xxs font-weight-light opacity-7 d-flex align-items-center">
                      <p className="text-dark text-xss my-auto">ABCDEF</p>
                    </div>
                    <div className="col text-uppercase text-secondary text-xxs font-weight-light opacity-7 d-flex align-items-center">
                      <p className="text-dark text-xss my-auto">2/4</p>
                    </div>
                    <div className="col">
                      <button type="button" className="btn btn-success bg-gradient-success my-auto mx-auto" name="button">
                        JOINED
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr />
            <h4 className="text-dark-custom">
              Gamename wager
            </h4>
            <div className="w-100 d-flex justify-content-between mb-1">
              <div className="border bg-gradient-dark text-white">
                00 new messages
              </div>
            </div>
            <div className="row my-4 border border-2 rounded-custom border-dark">
              <div className="col-sm-6">
                <div className="row border-bottom border-dark py-2">
                  <div className="col-3 text-uppercase text-secondary text-lg text-dark font-weight-bold opacity-7 text-truncate">
                    Username
                  </div>
                  <div className="col-3 text-uppercase text-secondary text-lg text-dark font-weight-bold opacity-7">
                    Game
                  </div>
                  <div className="col-3 text-uppercase text-secondary text-lg text-dark font-weight-bold opacity-7">
                    Location
                  </div>
                  <div className="col-3 text-uppercase text-secondary text-lg text-dark font-weight-bold opacity-7">
                    Price
                  </div>
                </div>
                <div className="row py-2">
                  <div className="col-3 text-uppercase text-secondary font-weight-light opacity-7 d-flex align-items-center">
                    <i className="material-icons opacity-10 text-success pe-2 magnify-md">account_circle</i>
                    <p className="mb-0 font-weight-bolder text-dark text-xss text-truncate">
                      JohnMichael
                    </p>
                  </div>
                  <div className="col-3 text-uppercase text-secondary text-xxs font-weight-light opacity-7">
                    <p className="text-dark text-xss my-auto">ABCDEF</p>
                  </div>
                  <div className="col-3 text-uppercase text-secondary text-xxs font-weight-light opacity-7">
                    <p className="text-dark text-xss my-auto">ABCDEF</p>
                  </div>
                  <div className="col-3 text-uppercase text-secondary text-xxs font-weight-light opacity-7">
                    <p className="text-dark text-xss my-auto">0000</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="row border-bottom border-dark py-2">
                  <div className="col-3 text-uppercase text-secondary text-lg text-dark font-weight-bold opacity-7">
                    Age
                  </div>
                  <div className="col-3 text-uppercase text-secondary text-lg text-dark font-weight-bold opacity-7">
                    Device
                  </div>
                  <div className="col-3 text-uppercase text-secondary text-lg text-dark font-weight-bold opacity-7">
                    Slot
                  </div>
                  <div className="col-3 text-uppercase text-secondary text-lg text-dark font-weight-bold opacity-7">
                    Time
                  </div>
                </div>
                <div className="row border-end border-dark py-2">
                  <div className="col text-uppercase text-secondary text-xxs font-weight-light opacity-7 d-flex align-items-center">
                    <p className="text-dark text-xss my-auto">00</p>
                  </div>
                  <div className="col text-uppercase text-secondary text-xxs font-weight-light opacity-7 d-flex align-items-center">
                    <p className="text-dark text-xss my-auto">ABCDEF</p>
                  </div>
                  <div className="col text-uppercase text-secondary text-xxs font-weight-light opacity-7 d-flex align-items-center">
                    <p className="text-dark text-xss my-auto">2/4</p>
                  </div>
                  <div className="col text-uppercase text-secondary text-xxs font-weight-light opacity-7">
                    <p className="text-dark text-sm my-auto">Today 1:00pm</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 border-top border-dark d-flex justify-content-center flex-column py-1">
                <div className="border border-dark text-dark col-sm-6 mx-auto text-center my-2 p-2">
                  A summary of the rules
                </div>
                <button type="button" className="btn btn-success bg-gradient-success my-auto mx-auto" name="button">
                  JOIN
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Profile;