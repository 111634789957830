export default function InputMessage({message}){
    if(message.type === 'danger'){
        return (
            <p className="text-danger m-0" dangerouslySetInnerHTML={{ __html: message.text}}>
            </p>
        )
    }else if(message.type === 'success'){
        return (
            <p className="text-success m-0" dangerouslySetInnerHTML={{ __html: message.text}}>
            </p>
        )
    }else{
        return (
            <></>
        )
    }
}