export default function MainNavigation() {
    return (
    <nav class="navbar navbar-expand-sm bg-gradient-white navbar-dark d-flex justify-content-between z-index-3 bg-white vw-100 position-fixed">

    <a class="navbar-brand w-auto" href="./">
      <img src="/logo.png" alt="Logo" class="img-fluid" width="30px"/>
    </a>

    <div class="col-sm-7 align-items-center desktop-search">
      <input type="text" class="w-100 p-2 form-control border rounded-1" placeholder="Search for games, players and wagers"/>
      <button class=" btn btn-success mb-0 rounded-1">
        <i class="material-icons text-white">search</i>
      </button>
    </div>

    <ul class="navbar-nav ml-auto flex-row">
      <li class="nav-item">
        <div class="dropdown notification-dropdown">
          <i class="material-icons btn mt-1 text-dark bell-icon magnify-md" data-bs-toggle="dropdown" aria-expanded="false">notifications</i>
          <ul class="dropdown-menu notifications py-0" aria-labelledby="dropdownMenuButton">
            <div class="overflow-scroll notification-box h-100 w-100 overflow-scroll">
              <li><a class="dropdown-item" href="./#">Something else here</a></li>
              <li><a class="dropdown-item" href="./#">Something else here</a></li>
              <li><a class="dropdown-item" href="./#">Something else here</a></li>
              <li><a class="dropdown-item" href="./#">Something else here</a></li>
              <li><a class="dropdown-item" href="./#">Something else here</a></li>
              <li><a class="dropdown-item" href="./#">Something else here</a></li>
              <li><a class="dropdown-item" href="./#">Something else here</a></li>
              <li><a class="dropdown-item" href="./#">Something else here</a></li>
              <li><a class="dropdown-item" href="./#">Something else here</a></li>
              <li><a class="dropdown-item" href="./#">Something else here</a></li>
              <li><a class="dropdown-item" href="./#">Something else here</a></li>
              <li><a class="dropdown-item" href="./#">Something else here</a></li>
              <li><a class="dropdown-item" href="./#">Something else here</a></li>
            </div>
          </ul>
        </div>
      </li>

      <li class="nav-item">
        <div class="dropdown notification-dropdown">
          <i class="material-icons btn mt-1 text-dark bell-icon magnify-md" data-bs-toggle="dropdown" aria-expanded="false">settings</i>
          <ul class="dropdown-menu notifications settings py-0" aria-labelledby="dropdownMenuButton">
            <li class="dropdown-item bg-gradient-white">
              <div class="h-100 w-100 p-2">
                  <div class="mt-2 d-flex justify-content-center">
                    <span class="material-icons">
                      light_mode
                    </span>
                    <div class="form-check form-switch ps-0 mx-1 my-auto">
                      <input class="form-check-input mt-1 ms-auto" type="checkbox" id="dark-version" onclick="darkMode(this)"/>
                    </div>
                    <span class="material-icons">
                      dark_mode
                    </span>
                  </div>
              </div>
            </li>
          </ul>
        </div>
      </li>

      <li class="nav-item d-block d-sm-none">
        <i class="material-icons btn px-3 mt-1 text-dark bell-icon magnify-md">search</i>
      </li>

      <li class="nav-item">
        <a href="./account.html" class="btn btn-success mt-1 mb-0 h-auto">LOGIN</a>
      </li>

    </ul>

    <div class="w-100 align-items-center mobile-search mt-3">
      <input type="text" class="w-100 p-2 form-control border rounded-1" placeholder="Search for games, players and wagers"/>
      <button class=" btn btn-success mb-0 rounded-1 text-white">
        <i class="material-icons text-white">search</i>
      </button>
    </div>

  </nav>
  )
}