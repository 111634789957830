import './Account.css';
import React, { useEffect, useRef, useState } from 'react';
import useQueryParams from '../../hooks/QueryParams';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/AuthContext';
import PasswordInput from '../../components/PasswordInput';
import InputMessage from '../../components/InputMessage';

function Account({ apiBaseURL, setAlerts, globalAlerts }) {

    const [
        authUser,
        setAuthUser,
        isLoggedIn,
        setIsLoggedIn
    ] = useAuth();

    const [ signInEmailMessages, setSignInEmailMessages ] = useState([]);

    const [ signInUsernameMessages, setSignInUsernameMessages ] = useState([]);

    const [ signInPasswordMessages, setSignInPasswordMessages ] = useState([
        {
            type: 'danger',
            text: 'Must have 5 or more characters'
        },
        {
            type: 'danger',
            text: 'Must have a minimum of 1 number or 1 capital letter or 1 special character'
        }
    ]);

    const [params] = useState(useQueryParams());

    const navigate = useNavigate();

    const loginBtn = useRef();

    const loginFirstInput = useRef();
    
    const signupBtn = useRef();
    
    const signupFirstInput = useRef();

    const showSignup = () => {
        signupBtn.current.click();
        navigate('?page=signup', {
            replace: true
        });
    }

    const showLogin = () => {
        loginBtn.current.click();

        navigate('?page=login', {
            replace: true
        });
    }

    useEffect(() => {
        if(isLoggedIn && authUser){
            navigate('/profile');
        }else{     
            if (params.page === 'signup') {
                showSignup();
            }
        }
    });

    function formatEmail(event){
        const input = event.target;
        if(!input.value.includes('@') && input.value !== ''){
            const length = input.value.length;
            input.type = 'text'; // wierd thing https://stackoverflow.com/questions/26658474/type-email-doesnt-support-selectionrange
            input.focus();
            input.value += '@gmail.com';
            input.setSelectionRange( length+1 , length);
            input.type = 'email';
        }
        if(input.checkValidity()){
            input.classList.remove('is-invalid');
            input.classList.add('is-valid');
        }else{
            input.classList.remove('is-valid');
            input.classList.add('is-invalid');
        }
    }

    async function checkForDuplicateEmail(event){
        const input = event.target;
        const data = new FormData();
        data.append('email', input.value);
        try {
            const request = await fetch(`${apiBaseURL}/check-email`, {
                method: 'POST',
                body: data,
                credentials: 'include'
            })
    
            if(request.status === 208){
                signInEmailMessages.push({
                    type: 'danger',
                    text: 'Email already in use <a href="/account" class="text-info">log in</a>'
                });
                setSignInEmailMessages([...signInEmailMessages]);
                input.classList.remove('is-valid');
                input.classList.add('is-invalid');
            }   
        } catch (error) {
            globalAlerts.push({
                type: 'error',
                message: 'Please check your connection'
            })
            setAlerts([...globalAlerts]);
        }
    }

    async function checkForDuplicateUsername(event){
        const input = event.target;
        const data = new FormData();
        data.append('username', input.value);
        try {
            const request = await fetch(`${apiBaseURL}/check-username`, {
                method: 'POST',
                body: data,
                credentials: 'include'
            })
    
            if(request.status === 208){
                signInUsernameMessages.push({
                    type: 'danger',
                    text: 'Username already in use'
                });
                setSignInUsernameMessages([...signInUsernameMessages]);
                input.classList.remove('is-valid');
                input.classList.add('is-invalid');
            }else{
                input.classList.remove('is-invalid');
                input.classList.add('is-valid');
            }              
        } catch (error) {
            globalAlerts.push({
                type: 'error',
                message: 'Please check your connection'
            })
            setAlerts([...globalAlerts]);
        }
         
    }

    const signupUser = async (event) => {
        event.preventDefault();
        const form = event.target;
        const inputs = form.elements;
        for (const input of inputs) {
            if (input.checkValidity()) {
                input.classList.add('is-valid');
                input.classList.remove('is-invalid');
            } else {
                input.classList.remove('is-valid');
                input.classList.add('is-invalid');
            }
        }

        globalAlerts.push({
            type: 'info',
            message: "Saving your information"
        });
        setAlerts([...globalAlerts]);

        try {
            const registerRequest = await fetch(`${apiBaseURL}/register`, {
                method: 'POST',
                body: new FormData(form),
                credentials: 'include'
            })

            const response = await registerRequest.json();

            if (registerRequest.ok) {
                globalAlerts.push({
                    type: 'success',
                    message: "Registered succesfully!"
                })
                setAlerts([...globalAlerts]);

                navigate(`/verification/${response[1]}`);
            } else {
                globalAlerts.push({
                    type: 'error',
                    message: "Failed to save please retry"
                })
                setAlerts([...globalAlerts]);
            }
        } catch (error) {
            globalAlerts.push({
                type: 'error',
                message: "Failed to save please retry"
            })
            setAlerts([...globalAlerts]);
        }

    }

    const loginUser = async (event) => {
        event.preventDefault();
        const form = event.target;
        const inputs = form.elements;
        for (const input of inputs) {
            if (input.checkValidity()) {
                input.classList.add('is-valid');
                input.classList.remove('is-invalid');
            } else {
                input.classList.remove('is-valid');
                input.classList.add('is-invalid');
            }
        }

        try {
            const loginRequest = await fetch(`${apiBaseURL}/login`, {
                method: 'POST',
                body: new FormData(form),
                credentials: 'include'
            })

            const loginResponse = await loginRequest.json();

            if (loginRequest.status === 200) {
                globalAlerts.push({
                    type: 'success',
                    message: "Login succesfully!"
                })
                setAuthUser(loginResponse);
                setIsLoggedIn(true);
                setAlerts([...globalAlerts]);
                navigate('/profile');
            } else {
                globalAlerts.push({
                    type: 'error',
                    message: loginResponse[0]
                })
                setAlerts([...globalAlerts]);
                setAuthUser(null);
                setIsLoggedIn(false);
            }
        } catch (error) {
            globalAlerts.push({
                type: 'error',
                message: "Failed to login please retry"
            })
            setAlerts([...globalAlerts]);
            setAuthUser(null);
            setIsLoggedIn(false);
        }

    }

    return (
        <main className="container-fluid px-0 main-bg-custom min-vh-100 py-0 py-sm-4">
            <div className="row justify-content-center align-content-center align-items-center h-100 overflow-auto">
                <div className="col-sm-4 bg-white border border-secondary rounded shadow h-100 vh-sm-100 d-block p-1 justify-content-center align-content-center align-items-center">
                    <div className="w-100 text-center">
                        <img src="/logo_light.png" className="img-fluid mx-auto col-4 col-sm-5" alt="" />
                    </div>
                    <ul className="nav nav-tabs border-0" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link border-start-0 border-end-0 border-top-0 active text-dark-custom" ref={loginBtn} data-bs-toggle="tab" data-bs-target="#login"
                                type="button" role="tab" aria-controls="home" aria-selected="true">
                                Login
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link border-start-0 border-end-0 border-top-0 text-dark-custom" ref={signupBtn} data-bs-toggle="tab" data-bs-target="#signup" type="button" role="tab" aria-controls="profile" aria-selected="false">
                                Sign Up
                            </button>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="login" role="tabpanel" aria-labelledby="home-tab">
                            <div className="login-modal w-90 mx-auto">
                                <div className="w-100 text-center text-dark-custom pt-2">
                                    <h4 className="text-dark-custom">Sign In</h4>
                                </div>
                                <form action="" className="w-100" onSubmit={async (e) => await loginUser(e)}>
                                    <div className="my-3 d-flex flex-column">
                                        <label className="form-label text-secondary font-weight-bold" htmlFor="loginEmail">Email</label>
                                        <input type="email" autoFocus onInput={ (e) => formatEmail(e)} name='email' id="loginEmail" ref={loginFirstInput} required className="form-control form-control-lg w-100 border border-secondary" />
                                    </div>
                                    <PasswordInput inputMessages={[]}/>
                                    <a href="/" className="text-success">Forgot Your Password?</a>
                                    <button type="submit" className="btn w-100 btn-success text-white rounded">
                                        LOGIN
                                    </button>
                                    <p>
                                        Don't have an account?
                                        <span className="text-decoration-none text-success cursor-pointer" onClick={() => showSignup()}>Sign Up</span>
                                    </p>
                                </form>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="signup" role="tabpanel" aria-labelledby="profile-tab">
                            <div className="register-modal w-90 mx-auto">
                                <div className="w-100 text-center text-dark-custom pt-2">
                                    <h4 className="text-dark-custom">Create An Account</h4>
                                </div>
                                <form action="" className="w-100" onSubmit={async (e) => await signupUser(e)}>
                                    <div className="my-3 d-flex flex-column">
                                        <label className="form-label text-secondary font-weight-bold" htmlFor='signupEmail'>Email</label>
                                        <input type="email" autoFocus id='signupEmail' onInput={ async (e) => {
                                            formatEmail(e);
                                            await checkForDuplicateEmail(e);
                                        } } required name='email' ref={signupFirstInput} className="form-control form-control-lg w-100 border border-secondary" />
                                        {
                                            signInEmailMessages.length > 0 ? signInEmailMessages.map((message) => 
                                            <InputMessage message={message} /> 
                                            ) : ''
                                        }
                                    </div>
                                    <div className="my-3 d-flex flex-column">
                                        <label className="form-label text-secondary font-weight-bold" htmlFor='signupUsername'>Username</label>
                                        <input type="text" required name='username' onInput={ async (e) => {
                                            await checkForDuplicateUsername(e);
                                        } }
                                        id='signupUsername' className="form-control form-control-lg w-100 border border-secondary" />
                                        {
                                            signInUsernameMessages.length > 0 ? signInUsernameMessages.map((message) => 
                                            <InputMessage message={message} /> 
                                            ) : ''
                                        }
                                    </div>
                                    <PasswordInput inputMessages={signInPasswordMessages} setInputMessages = { setSignInPasswordMessages }/>
                                    
                                    <button type="submit" className="btn btn-success w-100 text-white rounded">
                                        SIGN UP
                                    </button>

                                    <button type="button" className="btn btn-info w-100 text-white rounded">
                                        SIGN IN WITH GOOGLE
                                    </button>

                                    <p className="mb-0">
                                        Already have an account?
                                        <span className="text-decoration-none text-success cursor-pointer" onClick={() => showLogin()}>Log In</span>
                                    </p>
                                    <p>
                                        By continuing you agree to our <span className="text-success">terms of service</span>.
                                    </p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Account;
