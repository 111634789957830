import { useState } from "react";
import InputMessage from "./InputMessage";

export default function PasswordInput({ inputMessages, setInputMessages }) {
    const [showPassword, setShowPassword] = useState(true);

    const [showInputMessages, setShowInputMessages] = useState(false);

    function validatePassword(event){
        const input = event.target;
        if(input.value.length >= 5){
            if(containsNumber(input.value) || containsCapitalLetter(input.value) || containsSpecialCharacter(input.value)){
                setInputMessages([
                    {
                        type: 'success',
                        text: 'Must have 5 or more characters'
                    },
                    {
                        type: 'success',
                        text: 'Must have a minimum of 1 number or 1 capital letter or 1 special character'
                    }
                ])
            }else{
                setInputMessages([
                    {
                        type: 'success',
                        text: 'Must have 5 or more characters'
                    },
                    {
                        type: 'danger',
                        text: 'Must have a minimum of 1 number or 1 capital letter or 1 special character'
                    }
                ])
            }
        }else{
            if(containsNumber(input.value) || containsCapitalLetter(input.value) || containsSpecialCharacter(input.value)){
                setInputMessages([
                    {
                        type: 'danger',
                        text: 'Must have 5 or more characters'
                    },
                    {
                        type: 'success',
                        text: 'Must have a minimum of 1 number or 1 capital letter or 1 special character'
                    }
                ])
            }else{
                setInputMessages([
                    {
                        type: 'danger',
                        text: 'Must have 5 or more characters'
                    },
                    {
                        type: 'danger',
                        text: 'Must have a minimum of 1 number or 1 capital letter or 1 special character'
                    }
                ])
            }
        }


    }

    function containsNumber(string) {
        return /\d/.test(string);
    }

    function containsCapitalLetter(string){
        const regex = new RegExp(/[A-Z]/);
        return regex.test(string)
    }
    
    function containsSpecialCharacter(string){
        const regex = new RegExp(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/);
        return regex.test(string)
    }
    
    return (
        <>
            <div className="my-3 d-flex flex-column">
                <label className="form-label text-secondary font-weight-bold" htmlFor='signupPassword'>Password</label>
                <div className="position-relative">
                    <input type={showPassword ? 'text' : 'password'} 
                    onInput={ (e) => validatePassword(e)}
                    onFocus={()=> setShowInputMessages(true)} name='password' required id='signupPassword' className="form-control form-control-lg w-100 border border-secondary" />
                    <button type="button" onClick={() => setShowPassword(!showPassword)} className="position-absolute top-0 bottom-0 mb-auto h-auto d-flex align-items-center btn btn-info px-2 end-0">
                        {showPassword ? 'HIDE' : 'SHOW'}
                    </button>
                </div>
                {
                    inputMessages.length > 0 && showInputMessages ? inputMessages.map((message) =>
                        <InputMessage message={message} />
                    ) : ''
                }
            </div>
        </>
    )
}