import './Verification.css';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { useAuth } from '../../hooks/AuthContext';

function Verification({ apiBaseURL, setAlerts, globalAlerts }) {

    const [
        authUser,
        setAuthUser,
        isLoggedIn,
        setIsLoggedIn
    ] = useAuth();

    const [showPassword, setShowPassword ] = useState(true);

    const [countries, setCountries] = useState([
        {
            id: '',
            name: 'Choose one',
            code: ''
        }
    ]);

    const [counties, setCounties] = useState([
        {
            id: '',
            name: 'Choose one',
            code: ''
        }
    ]);

    const [subcounties, setSubcounties] = useState([
        {
            id: '',
            name: 'Choose one',
            code: ''
        }
    ]);

    const [countyVisibility, setCountyVisibility] = useState('');

    const [isGeoLocationsFetched, setIsGeoLocationsFetched] = useState(false);

    const { verificationtoken } = useParams();

    const navigate = useNavigate();

    const loginBtn = useRef();
    
    const signupBtn = useRef();

    useEffect(() => {
        if(isLoggedIn && authUser){
            navigate('/profile');
        }else{
            async function checkVerificationToken() {
                const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
                  try {
                    const formBody = new FormData();
                    formBody.append('verificationtoken' ,verificationtoken);
                    const request = await fetch(`${apiBaseURL}/verificationtoken`, {
                      credentials: 'include',
                      method: 'POST',
                      body: formBody
                    });

                    if(request.status !== 200){
                        navigate('/account');
                    }
                    
                  } catch (error) {
                    globalAlerts.push({
                        type: 'error',
                        message: "Failed to verify!"
                    })
                    setAlerts([...globalAlerts]);
                    navigate('/account');
                  }
                }
                checkVerificationToken();
        }
    }, []);

    const loadGeoLocationData = async () => {
        if (!isGeoLocationsFetched) {
            try {
                const countriesRequest = await fetch(`${apiBaseURL}/countries`);

                const countriesResponse = await countriesRequest.json();

                countriesResponse.unshift(countries[0]);

                setCountries([...countriesResponse]);

                const countiesRequest = await fetch(`${apiBaseURL}/counties`);

                const countiesResponse = await countiesRequest.json();

                countiesResponse.unshift(counties[0]);

                setCounties(countiesResponse);

                const subcountiesRequest = await fetch(`${apiBaseURL}/sub-counties`);

                const subcountiesResponse = await subcountiesRequest.json();

                subcountiesResponse.unshift(subcounties[0]);

                setSubcounties(subcountiesResponse);

                setIsGeoLocationsFetched(true);
            } catch (error) {
                globalAlerts.push({
                    type: 'error',
                    message: "Couldn't fetch geolocations. Please refresh"
                })
                setAlerts([...globalAlerts])
            }

        }
    }

    const toggleCountyVisibility = (event) => {
        if (event.target.selectedOptions[0].getAttribute('data-code') === 'KE') {
            setCountyVisibility('');
        } else {
            setCountyVisibility('invisible');
        }
    }

    const verifyUser = async (event) => {
        event.preventDefault();
        const form = event.target;
        const inputs = form.elements;
        for (const input of inputs) {
            if (input.checkValidity()) {
                input.classList.add('is-valid');
                input.classList.remove('is-invalid');
            } else {
                input.classList.remove('is-valid');
                input.classList.add('is-invalid');
            }
        }

        if (!moment(form.year.value + '-' + form.month.value + '-' + form.date.value, 'YYYY-MM-DD', true).isValid()) {
            globalAlerts.push({
                type: 'error',
                message: "Invalid date selection"
            })
            setAlerts([...globalAlerts]);
            form.date.classList.add('is-invalid');
            form.date.classList.remove('is-valid');
            return;
        } else {
            form.date.classList.remove('is-invalid');
            form.date.classList.add('is-valid');

            globalAlerts.push({
                type: 'info',
                message: "Saving your information"
            });
            setAlerts([...globalAlerts]);

            try {
                const verificationRequest = await fetch(`${apiBaseURL}/verify`, {
                    method: 'POST',
                    body: new FormData(form),
                    credentials: 'include'
                })

                const verificationResponse = await verificationRequest.json();

                if (verificationRequest.ok) {
                    globalAlerts.push({
                        type: 'success',
                        message: "Finished registeration succesfully!"
                    })
                    setAuthUser(verificationResponse);
                    setIsLoggedIn(true);
                    setAlerts([...globalAlerts]);

                    navigate('/profile');
                } else {
                    globalAlerts.push({
                        type: 'error',
                        message: "Failed to verify please retry"
                    })
                    setAlerts([...globalAlerts]);
                }
            } catch (error) {
                globalAlerts.push({
                    type: 'error',
                    message: "Failed to verify please retry"
                })
                setAlerts([...globalAlerts]);
            }

        }

    }

    return (
        <main className="container-fluid px-0 main-bg-custom min-vh-100 py-0 py-sm-4">
            <div className="row justify-content-center align-content-center align-items-center h-100 overflow-auto">
                <div className="col-sm-4 bg-white border border-secondary rounded shadow h-100 vh-sm-100 d-block p-1 justify-content-center align-content-center align-items-center">
                    <div className="w-100 text-center">
                        <img src="/logo_light.png" className="img-fluid mx-auto col-4 col-sm-5" alt="" />
                    </div>
                    <ul className="nav nav-tabs border-0 d-none" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link border-start-0 border-end-0 border-top-0 active text-dark-custom" ref={loginBtn} data-bs-toggle="tab" data-bs-target="#login"
                                type="button" role="tab" aria-controls="home" aria-selected="true">
                                Login
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link border-start-0 border-end-0 border-top-0 text-dark-custom" ref={signupBtn} data-bs-toggle="tab" data-bs-target="#signup" type="button" role="tab" aria-controls="profile" aria-selected="false">
                                Sign Up
                            </button>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="signup" role="tabpanel" aria-labelledby="profile-tab">
                            <div className="register-modal w-90 mx-auto">
                                <div className="w-100 text-center text-dark-custom pt-2">
                                    <h4 className="text-dark-custom">Finish Account Setup</h4>
                                </div>
                                <form action="" className="w-100" onSubmit={async (e) => await verifyUser(e)}>
                                    <input type='hidden' value={ verificationtoken } name='verificationtoken'/>
                                    <div className="my-3 d-flex flex-wrap p-0">
                                        <div className="col-12 p-0 text-secondary font-weight-bold d-flex">
                                            Date of birth
                                            <p className="ms-1 mb-0">
                                                (Used in pairing with other players)
                                            </p>
                                        </div>
                                        <div className="col d-flex flex-column">
                                            <label className="form-label text-secondary font-weight-bold">Date</label>
                                            <input type="number" step="1" min="1" max="31" required name='date' autoFocus className="form-control form-control-lg w-100 border border-secondary" />
                                        </div>
                                        <div className="col d-flex flex-column px-1">
                                            <label className="form-label text-secondary font-weight-bold">Month</label>
                                            <select name="month" required className="form-control form-control-lg border border-secondary">
                                                <option value="01">January</option>
                                                <option value="02">February</option>
                                                <option value="03">March</option>
                                                <option value="04">April</option>
                                                <option value="05">May</option>
                                                <option value="06">June</option>
                                                <option value="07">July</option>
                                                <option value="08">August</option>
                                                <option value="09">September</option>
                                                <option value="10">October</option>
                                                <option value="11">November</option>
                                                <option value="12">December</option>
                                            </select>
                                        </div>
                                        <div className="col d-flex flex-column">
                                            <label className="form-label text-secondary font-weight-bold">Year</label>
                                            <input type="number" required name='year' step="1" min="1900" className="form-control form-control-lg w-100 border border-secondary" />
                                        </div>
                                    </div>

                                    <div className="my-3 d-flex">
                                        <div className="col d-flex flex-column px-1">
                                            <label className="form-label text-secondary font-weight-bold">Country</label>
                                            <select name="country_id" required className="form-control form-control-lg border border-secondary" onClick={async () => { await loadGeoLocationData() }} onChange={(event) => toggleCountyVisibility(event)}>
                                                {
                                                    countries.map(country => (
                                                        <option data-code={country.code} value={country.id} key={country.id}>
                                                            {country.name}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className={"col d-flex flex-column px-1 " + countyVisibility}>
                                            <label className="form-label text-secondary font-weight-bold">County</label>
                                            <select name="county_id" className="form-control form-control-lg border border-secondary">
                                                {
                                                    counties.map(county => (
                                                        <option data-code={county.code} value={county.id} key={county.id}>
                                                            {county.name}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className={"col d-flex flex-column px-1 " + countyVisibility}>
                                            <label className="form-label text-secondary font-weight-bold">Subcounty</label>
                                            <select name="sub_county_id" className="form-control form-control-lg border border-secondary">
                                                {
                                                    subcounties.map(subcounty => (
                                                        <option data-code={subcounty.code} value={subcounty.id} key={subcounty.id}>
                                                            {subcounty.name}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="my-3 d-flex flex-column">
                                        <label className="form-label text-secondary font-weight-bold" htmlFor='verificationCode'>Verification Code</label>
                                        <input type="text" required name='verification_code' id='verificationCode' className="form-control form-control-lg w-100 border border-secondary" />
                                    </div>
                                    <button type="submit" className="btn btn-success w-100 text-white rounded">
                                        FINISH REGISTRATION
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Verification;
